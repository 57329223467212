:host {
    .form-textarea {
        position: relative;
        height: 45px;
        width: 100%;

        .ml-icon {
            position: absolute;
            right: 14px;
            top: 10px;
            z-index: 2;
            cursor: pointer;
        }
    }

    .textarea {
        height: 100%;
        width: 100%;
        border: 1px solid #707070;
        border-radius: 7px;
        padding: 0 10px;
        outline: none;
        background: none;
    }

    ::placeholder {
        color: transparent;
    }

    textarea:focus {
        border: none;
        outline: 1px solid #0136FE;
    }

    .label {
        position: absolute;
        top: 13px;
        left: 15px;
        padding: 0 4px;
        background-color: white;
        color: #000000d9;
        transition: 0.5s;
        z-index: 0;
    }

    .textarea:focus + .label {
        top: -10px;
        left: 3px;
        z-index: 3;
        font-size: 14px;
        font-weight: 600;
        color: #0136FE;
    }

    .textarea:not(:placeholder-shown) + .label {
        top: -10px;
        left: 3px;
        z-index: 3;
        font-weight: 600;
    }

    &.textarea-disabled,
    &.textarea-readonly {
        color: #f9f9f9;

        .textarea {
            background-color: #f9f9f9;
            border: 1px solid #707070;
            box-shadow: none !important;
            cursor: not-allowed;
        }
    }
}
