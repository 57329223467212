@import '../../../node_modules/bootstrap/scss/bootstrap';


@import 'variables';

@import 'container';

@import 'components/sidebar';
@import 'components/button';
@import 'components/checkbox';
@import 'components/header';
@import 'components/input-search';
@import 'components/otp-input';
@import 'components/table';
@import 'components/textarea';
@import 'components/tooltip-password';
@import 'components/input';

@import 'content-table';

@import 'fonts';

.text-bg-success {
    background: #ECFFF3 !important;
    color: #16CC5A !important;
    font-size: 14px;
    border-radius: 15px;
}

.text-bg-primary {
    background: #809BFF !important;
    font-size: 14px;
    border-radius: 15px;
}

.text-bg-danger {
    background: #FFE9E9 !important;
    font-size: 14px;
    border-radius: 15px;
    color: #7E0000 !important;
}

.text-bg-danger {
    font-size: 14px;
}

.text-primary {
    color: #0136fe !important;
}

.br-10 {
    border-radius: 10px !important;
}

.br-0 {
    border-radius: 0 !important;
}

.mr-1 {
    padding-right: 10px;
}

.mr-2 {
    padding-right: 20px;
}

.btr {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btl {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
}


.select-class {
    .milio-select-container {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.no-r {
    border-radius: 0 !important;
    border-left: 0 !important;
}

.milio-input {
    input:disabled {
        background: #ededed6b !important;
    }
}

.input-disabled {
    .form-input {
        .label {
            background-color: transparent !important;
        }
    }
}

.milio-modal {
    .modal-window {
        .modal-header {
            padding: 1rem 2rem 1em !important;

            .title {
                font-size: 22px !important;
                font-weight: bold;
            }
        }

        .modal-body {
            padding: 1rem 2rem 1em !important;
        }

        .modal-footer {
            padding: 1rem 1rem 2rem !important;
            justify-content: center !important;
            display: block !important;
        }
    }
}

.milio-close {
    color: #2c3e50 !important;
}

.opened {
    z-index: 10 !important;
}

select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1.2em;
    border: 0.8px solid #000000;
}

select:focus {
    border: 2px solid #272727 !important;
}

.cursor-pointer {
    cursor: pointer;
}

h5, .h5 {
    font-size: inherit;
}

.mp-title {
    font-weight: bold;
    font-size: 16px;
}

.toast-container {
    position: fixed;
}

.link {
    color: #0136FE;
}
.border-dark{
    border: 1px solid  $black;
}
.p-title {
    font-weight: 600;
    font-size: 16px;
}

.max-short-cut{
    max-width: 10rem;
}

@media (min-width: 1560px) {
    .max-short-cut{
        max-width: 100%!important;
    }
  }

.text-short{
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    display:block;
    word-wrap: break-word;
}
