$checkbox-size: 16px;
$margin: 16px;
$margin-small: $margin / 2;
$text-lighter: #171717;
$brand: #0136FE;


input[type="checkbox"], input[type="radio"] {
    position: relative !important;
    appearance: none;
    margin: $margin-small;
    box-sizing: content-box;
    overflow: hidden;

    // circle
    &:before {
        content: '';
        display: block;
        box-sizing: content-box;
        width: $checkbox-size;
        height: $checkbox-size;
        border: 2px solid $text-lighter;
        transition: 0.2s border-color ease;
    }

    &:checked:before {
        transition: 0.5s border-color ease;
    }

    &:disabled:before {
        border-color: $text-lighter;
        background-color: $text-lighter;
    }

    // dot
    &:after {
        content: '';
        display: block;
        position: absolute;
        box-sizing: content-box;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        background-color: $brand;
        width: $checkbox-size;
        height: $checkbox-size;
        border-radius: 100vh;
        transform: translate(-50%, -50%) scale(0);
    }

    &[type="radio"] {
        &:before {
            border-radius: 100vh;
        }

        &:after {
            width: $checkbox-size;
            height: $checkbox-size;
            border-radius: 100vh;
            transform: translate(-50%, -50%) scale(0);
        }

        &:checked:after {
            animation: toggleOnRadio 0.2s ease forwards;
        }
    }

    &[type="checkbox"] {
        &:before {
            border-radius: $checkbox-size / 4;
        }

        &:after {
            width: $checkbox-size * 0.6;
            height: $checkbox-size;
            border-radius: 0;
            transform: translate(-50%, -85%) scale(0) rotate(45deg);
            background-color: transparent;
            box-shadow: 4px 4px 0 0 $brand;
        }

        &:checked:after {
            animation: toggleOnCheckbox 0.2s ease forwards;
        }
    }

    &[type="checkbox"].filled {
        &:before {
            border-radius: $checkbox-size / 4;
            transition: 0.2s border-color ease, 0.2s background-color ease;
        }

        &:checked:not(:disabled):before {
            background-color: $brand;
        }

        &:not(:disabled):after {
            box-shadow: 4px 4px 0 0 white;
        }
    }
}

@keyframes toggleOnCheckbox {
    0% {
        opacity: 0;
        transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }

    70% {
        opacity: 1;
        transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }

    100% {
        transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
}

@keyframes toggleOnRadio {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }

    70% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(0.9);
    }

    100% {
        transform: translate(-50%, -50%) scale(0.8);
    }
}
