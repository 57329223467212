.tooltip-password {
    position: relative;
    z-index: 11;

    .tooltip-body {
        visibility: hidden;
        position: absolute;
        z-index: 2;
        width: 290px;
        color: white;
        font-size: 12px;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        top: -15px;
        left: 102%;
        font-weight: normal;
        background-color: white;

        i {
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -12px;
            width: 12px;
            height: 24px;
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
            }
        }

        .tooltip-title {
            padding: {
                top: 5px;
                bottom: 5px;
            }
            text-align: center;

            strong {
                color: #2c3e50;
            }
        }

        .text-danger {
            font-size: 1em;
        }
    }

    &:hover .tooltip-body {
        visibility: visible;
        opacity: 1;
    }
    &:focus .tooltip-body {
        visibility: visible;
        opacity: 1;
    }

    .tooltip-body-errors {
        visibility: visible;
        opacity: 1;
    }

    @media (max-width: 991.98px) {
        .tooltip-body {
            top: -185px;
            left: 10%;
        }
    }
}
