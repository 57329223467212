$font-family: 'Satoshi-Regular', sans-serif;
$font-size: 14px;

// Font
$font-size-base: 0.875rem !default;
$font-size-xl: 1.25rem !default;
$font-size-lg: 1rem !default;
$font-size-sm: 0.75rem !default;
$font-size-xs: 0.65rem !default;

// Color
$brand-primary: #0136fe !default;
$brand-danger: #7e0000 !default;
$brand-success: #198754 !default;
$brand-secondary: #6c757d !default;
$white: #fff !default;
$black: #000 !default;
$red: #de4436 !default;
$orange: #fa8c16 !default;
$gold: #ffc107 !default;
$green: #52c41a !default;
$blue: #0136fe !default;
$cyan: #00c9a7 !default;
$purple: #886cff !default;
$purple-ligth: #809bff !default;
$magenta: #eb2f96 !default;
$volcano: #fa541c !default;
$lime: #a0d911 !default;
$geekblue: #2f54eb !default;
$gray-lighter: #ededed !default;
$gray-lightest: #f1f2f3 !default;
$highlight-bg: #fff3cd;

// Border
$border-color: #c9c5c5 !default;
$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-xs: 0.125rem !default;
$border-radius-circle: 50% !default;
$border-radius-round: 50px !default;
$border-width: 1px !default;
