@use 'sass:math';

$main-fs: 14px;
$module: 10px;
$module-rem: math.div($module * 1rem, $main-fs);
$animation: ease-in-out;
$black: #000;

//Media
@mixin max543 {
    @media (max-width: 543px) {
        @content;
    }
}

@mixin min544 {
    @media (min-width: 544px) {
        @content;
    }
}

@mixin max767 {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin min768 {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin max991 {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin min992 {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin max1199 {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin min1200 {
    @media (min-width: 1200px) {
        @content;
    }
}

.sidebar {
    min-width: 240px;
    max-width: 240px;
    background: #ffffff;
    color: $black;
    transition: all 0.3s;
    position: relative;
    box-shadow: rgb(0 0 0 / 18%) 0 2px 4px;
    height: 100%;
    z-index: 5;

    .brand-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
    }

    &.active {
        margin-left: -300px;

        .custom-menu {
            margin-right: -50px;
        }
    }

    .menu-ul {
        list-style-type: none;
        padding-right: 10px;
        padding-left: 10px;

        .menu-item {
            font-weight: 700;
            position: relative;

            .group-title {
                color: $black;
                display: block;
                opacity: 0.3;
                text-transform: uppercase;
            }

            &:first-child {
                .group-title {
                    padding-top: 0;
                }
            }

            .item-link {
                align-items: center;
                background: transparent;
                display: flex;
                padding: 10px 30px 10px 14px;
                margin: 0.2rem 1rem;
                position: relative;
                text-decoration: none;

                .link-text {
                    flex-grow: 2;
                    flex-shrink: 1;
                    font-size: 18px;
                    position: relative;
                    font-weight: 700;
                    font-family: 'Satoshi-Bold', sans-serif;
                    color: #171717;
                    opacity: 1;
                    height: auto;
                }

                .link-badge {
                    margin: 0 ($module-rem * 0.5);
                }

                .link-icon {
                    margin: 0 ($module-rem * 0.5);
                    opacity: 0.25;
                    padding: 0;
                    transition: none;
                }

                &:hover {
                    background: #ece6ff;
                    color: #7c4efe;
                }
            }

            .sub {
                // background: #ECE6FF;
                list-style-type: none;
                overflow: hidden;
                margin: {
                    top: 0;
                    bottom: 0;
                    left: 0.4rem;
                    right: 1rem;
                }
                padding: 0;

                .menu-item {
                    padding: 0 !important;
                    font-weight: 400;
                    padding-left: $module-rem * 0.5;
                    padding-right: $module-rem * 0.5;

                    &:after {
                        display: none;
                    }
                    .item-link {
                        margin: -0.1rem auto !important;
                        span {
                            // font-size: 0.9em;
                            font-size: 16px;
                            margin-left: 2rem;
                        }
                    }
                }
            }

            &.active {
                &:after {
                    transform: translateX(4px);
                    transition: 0.2s transform 0.2s $animation;
                }

                > .item-link {
                    background: #ece6ff;
                    color: #7c4efe;

                    .link-caret {
                        transform: rotate(90deg);
                    }
                }
            }

            &.has-sub {
                position: relative;

                > .item-link {
                    position: relative;

                    .caret {
                        display: block;
                    }
                }

                &.active {
                    > .item-link {
                        .caret {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }

    .custom-menu {
        display: inline-block;
        position: absolute;
        top: 24px;
        right: 0;
        margin-right: -20px;

        .btn {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            padding: 0.56em;

            &.btn-primary {
                background: #ffffff !important;
                border-color: #ffffff !important;

                &:hover,
                &:focus {
                    background: #f5f5f5 !important;
                    border-color: #f5f5f5 !important;
                }
            }
        }
    }

    .link-active {
        color: #6c5ce7 !important;
    }

    // @media (max-width: 1199.98px) {
    //     margin-left: -300px;

    //     .brand-logo {
    //         margin-top: 0;
    //     }

    //     &.active {
    //         margin-left: 0;
    //         position: absolute;
    //     }

    //     .custom-menu {
    //         margin-right: -50px !important;
    //         top: 10px !important;
    //     }
    // }

    .btn {
        &.btn-primary {
            background: #0136fe;
            border-color: #0136fe;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: grid;
            align-items: center;

            &:hover,
            &:focus {
                background: #0136fe !important;
                border-color: #0136fe !important;
            }
        }
    }
}
