$gutter-x: 1.5rem;
$gutter-y: 0;

html {
    width: 100%;
}

body {
    overflow-x: hidden !important;
    margin: 0;
    font-family: 'Satoshi-Regular', sans-serif;
    font-size: 14px;
}

a {
    color: #0136fe;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: darken(#0136fe, 5%);
    }

    &:active {
        color: #096dd9;
    }

    &:active,
    &:hover {
        text-decoration: none;
        outline: 0;
    }

    &:focus {
        text-decoration: none;
        outline: 0;
    }
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    padding-right: calc($gutter-x * 0.5);
    padding-left: calc($gutter-x * 0.5);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container-sm, .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container-md, .container-sm, .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1320px;
    }
}

.container-center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.wrap-content {
    width: 370px;
    background: transparent;

    .arrow-content {
        padding-right: 20px;
        padding-top: 5px;
        cursor: pointer;
    }
}

@media (max-width: 575.98px) {
    .container-center {
        position: inherit;
        margin-right: 0;
        transform: none;
        justify-content: center;
        display: flex;
    }

    .wrap-content {
        width: 330px;

        .arrow-content {
            position: absolute;
            padding-right: 0;
            top: -50px;
            background: #F2F2F2;
            left: -15px;
            border-radius: 0 20px 20px 0;
            padding-top: 0;

            img {
                margin: 7px 18px 7px 30px;
            }
        }
    }
}

