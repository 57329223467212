table {
    border: none;
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;

    > tbody {
        border-radius: 10px;
    }

    th, td {
        border: none
    }

    .striped tr {
        border-bottom: none
    }

    .striped > tbody > tr > td {
        border-radius: 0
    }

    .highlight > tbody > tr {
        -webkit-transition: background-color .25s ease;
        transition: background-color .25s ease
    }

    tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12)
    }

    td, th {
        padding: 15px 5px;
        display: table-cell;
        border-radius: 2px
    }
}

@media (max-width: 767.98px) {
    table.responsive-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative;

        td:empty:before {
            content: '\00a0'
        }

        th, table.responsive-table td {
            margin: 0;
            vertical-align: top
        }

        thead {
            display: block;
        }

        thead tr {
            display: block;
            padding: 0 10px 0 0
        }

        thead tr th::before {
            content: "\00a0"
        }

        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: auto;
            white-space: nowrap;
            border-radius: 10px;
        }

        tbody tr {
            display: inline-block;
            vertical-align: top;
            line-height:30px;
        }

        th {
            display: block;
        }

        td {
            display: block;
            min-height: 1.25em;
        }

        tr {
            border-bottom: none;
            padding-bottom: 20px;
        }

        thead {
            border: 0;
            border-right: 1px solid rgba(0, 0, 0, 0.12)
        }
    }

}
