.border-radius-input {
    border-radius: 0.75em !important;
    .milio-select-container {
        border-radius: 0.75em !important;
    }
}

.input-search {
    border-bottom: none !important;
    border-bottom-left-radius: initial !important;
    border-bottom-right-radius: initial !important;
}
.input-search input:focus {
    border-bottom: none !important;
    border-bottom-left-radius: initial !important;
    border-bottom-right-radius: initial !important;
    border: 1px solid #707070 !important;
    outline: none !important;
}
