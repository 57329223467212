@import url('../node_modules/@fortawesome/fontawesome-free/css/all.min.css');
@import 'src/assets/styles/app';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';

.text-bg-created {
    background: #809bff !important;
    color: #ffffff !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
}

.text-bg-sent {
    background: #809bff !important;
    color: #ffffff !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.text-bg-opened {
    background: #ffeddd !important;
    color: #e59449 !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.text-bg-approve,
.text-bg-paid {
    background: #ecfff3 !important;
    color: #16cc5a !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.text-bg-declined,
.text-bg-rejected {
    background: #ffe9e9 !important;
    color: #7e0000 !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.text-bg-to-approve {
    background: #f7f7f7 !important;
    color: #707070 !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.text-bg-in-process {
    background: #f7f7f7 !important;
    color: #809bff !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.text-bg-in-transit {
    background: #d1f1ff !important;
    color: #000000 !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.text-bg-in-rejected {
    background: #ffdfdf !important;
    color: #920000 !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.text-bg-approved,
.text-bg-paid,
.text-bg-collected {
    background: #ecfff3 !important;
    color: #16cc5a !important;
    font-size: 12px;
    border-radius: 15px;
    padding: 5px 12px;
    width: 104px;
}

.status-badge {
    font-size: 12px;
    border-radius: 50px;
    padding: 5px 12px;
    min-width: 81px;
}

.status-badge--green {
    background: #e7f9ff !important;
}

.status-badge--light-green {
    background: #ecfff3 !important;
}

.status-badge--red {
    background: #ffe9e9 !important;
}

.status-badge--orange {
    background: #ffeddd !important;
}

.status-badge--blue {
    background: #809bff !important;
}

.status-badge--black-text {
    color: #000 !important;
}

.status-badge--red-text {
    color: #7e0000 !important;
}

.status-badge--blue-text {
    color: #0036fe !important;
}

.status-badge--green-text {
    color: #16cc5a !important;
}

.status-badge--white-text {
    color: #fff !important;
}

.status-badge--orange-text {
    color: #e59449 !important;
}

.text-bg-danger {
    font-size: 14px;
}

.text-primary {
    color: #0136fe !important;
}

.br-10 {
    border-radius: 10px !important;
}

.br-0 {
    border-radius: 0 !important;
}

.mr-1 {
    padding-right: 10px;
}

.mr-2 {
    padding-right: 20px;
}

.btr {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btl {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
}

.select-class {
    .milio-select-container {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.no-r {
    border-radius: 0 !important;
    border-left: 0 !important;
}

.milio-input {
    input:disabled {
        background: #ededed6b !important;
    }
}

.input-disabled {
    .form-input {
        .label {
            background-color: transparent !important;
        }
    }
}

.milio-modal {
    .modal-window {
        .modal-header {
            padding: 1rem 2rem 1em !important;

            .title {
                font-size: 22px !important;
                font-weight: bold;
            }
        }

        .modal-body {
            padding: 1rem 2rem 1em !important;
        }

        .modal-footer {
            padding: 1rem 1rem 2rem !important;
            justify-content: center !important;
            display: block !important;
        }
    }
}

.milio-close {
    color: #2c3e50 !important;
}

.opened {
    z-index: 10 !important;
}

select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1.2em;
    border: 0.8px solid #000000;
}

select:focus {
    border: 2px solid #272727 !important;
}

.mp-text-muted {
    color: #707070;
}

milio-color-options {
    display: flex;
    padding-top: 10px;
    justify-content: space-between;

    .color-box {
        width: 50px !important;
        height: 50px !important;
        margin-right: 0 !important;
    }

    @media (max-width: 575.98px) {
        .color-box {
            width: 2.5rem !important;
            height: 2.5rem !important;
        }
    }
}

milio-input-picker-color {
    .picker-color {
        width: 50px !important;
        height: 50px !important;
    }

    .picker-color-box {
        height: 46px !important;
        justify-content: space-between;
        align-items: center;

        .input {
            margin-top: 0 !important;
            width: calc(100% - 60px);
            color: #707070;
            font-size: 16px;
            font-weight: 400;
        }
    }
}
.mp-print {
    display: none !important;
}

@media print {
    .mp-data {
        display: none !important;
    }
    .mp-print {
        display: block !important;
    }
}

.LyImageCropper-area-d {
    box-shadow: 0 0 0 20000px rgba(36, 36, 36, 0.5) !important;
}

.toast-container .ngx-toastr {
    border: 1px solid #7d7d7d;
    border-left: 10px solid #0136fe;
    box-shadow: none;
    border-radius: 6px;
    padding: 15px 20px 15px 70px;
    margin-right: 15px;
}
.toast-message {
    font-weight: 400;
    font-family: 'Satoshi-Regular', sans-serif;
}

.no-border-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.no-border-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
