html {
    font-size: 100%; /* 100% = 16px */
}

.ff-satoshi-black {
    font-weight: 700;
    font-family: 'Satoshi-Black', sans-serif;
    color: #171717;
    font-size: 24px;
}

.ff-satoshi-black-700 {
    font-weight: 700;
    font-family: 'Satoshi-Black', sans-serif;
    color: #171717;
}

.ff-satoshi-black-9 {
    font-weight: 900;
    font-family: 'Satoshi-Black', sans-serif;
    color: #171717;
    font-size: 26px;
}

.ff-satoshi-black-8 {
    font-weight: 800;
    font-family: 'Satoshi-Black', sans-serif;
    color: #171717;
    font-size: 26px;
}

.ff-satoshi-bold-500-auto {
    font-weight: 500;
    font-family: 'Satoshi-Bold', sans-serif;
    color: #171717;
}

.ff-satoshi-bold-500 {
    font-weight: 500;
    font-family: 'Satoshi-Bold', sans-serif;
    color: #171717;
    font-size: 18px;
}

.ff-satoshi-bold-400 {
    font-weight: 400;
    font-family: 'Satoshi-Bold', sans-serif;
    color: #171717;
    font-size: 16px;
}

.ff-satoshi-medium-400 {
    font-weight: 400;
    font-family: 'Satoshi-Medium', sans-serif;
    color: #171717;
    font-size: 16px;
}

.ff-satoshi-medium-500 {
    font-weight: 500;
    font-family: 'Satoshi-Medium', sans-serif;
    color: #171717;
    font-size: 16px;
}

.ff-satoshi-regular-500 {
    font-weight: 500;
    font-family: 'Satoshi-Regular', sans-serif;
    color: #707070;
    font-size: 16px;
}

.ff-satoshi-bold-600 {
    font-weight: 600;
    font-family: 'Satoshi-Bold', sans-serif;
    color: #171717;
    font-size: 22px;
}

.ff-satoshi-bold-700 {
    font-weight: 700;
    font-family: 'Satoshi-Bold', sans-serif;
    color: #171717;
}

.ff-satoshi-bold {
    font-weight: 600;
    font-family: 'Satoshi-Bold', sans-serif;
    color: #171717;
}
.f-size-0-6 {
    font-size: 10px !important;
}
.f-size-0-7 {
    font-size: 11px !important;
}
.f-size-0-8 {
    font-size: 12px !important;
}
.f-size-1 {
    font-size: 14px !important;
}
.f-size-2 {
    font-size: 15px !important;
}
.f-size-3 {
    font-size: 16px !important;
}
.f-size-4 {
    font-size: 17px !important;
}
.f-size-5 {
    font-size: 18px !important;
}
.f-size-6 {
    font-size: 19px !important;
}
.f-size-7 {
    font-size: 20px !important;
}
.f-size-8 {
    font-size: 22px !important;
}
.f-size-9 {
    font-size: 24px !important;
}

.ff-satoshi-bold {
    font-family: 'Satoshi-Bold', sans-serif;
    color: #171717;
}

.ff-satoshi-medium {
    font-family: 'Satoshi-Medium', sans-serif;
    color: #171717;
}

.ff-satoshi-regular {
    font-family: 'Satoshi-Regular', sans-serif;
    color: #171717;
}

.f-w-400 {
    font-weight: 400;
}

.f-w-500 {
    font-weight: 500;
}

.f-w-600 {
    font-weight: 600;
}

.f-w-700 {
    font-weight: 700;
}

.lh-12 {
    line-height: 12px;
}

// 12px
.fs-0-625 {
    font-size: 0.625rem;
}

// 12px
.fs-0-75 {
    font-size: 0.75rem;
}

// 14px
.fs-0-85 {
    font-size: 0.85rem;
}

// 16px
.fs-1em {
    font-size: 1em;
}

// 18px
.fs-1-125 {
    font-size: 1.125em;
}

// 20px
.fs-1-25 {
    font-size: 1.25em;
}

// 22px
.fs-1-375 {
    font-size: 1.375em;
}

// 24px
.fs-1-503 {
    font-size: 1.503em;
}

// 25px
.fs-1-563 {
    font-size: 1.563em;
}

// 30px
.fs-1-875 {
    font-size: 1.875em;
}

// 35px
.fs-2-188 {
    font-size: 2.188em;
}

// 45px
.fs-2-813 {
    font-size: 2.813em;
}

.fc-707070 {
    color: #707070;
}

.fc-819BFF {
    color: #819bff;
}

.fc-242424 {
    color: #242424;
}

.fc-171717 {
    color: #171717;
}

.fc-212121 {
    color: #212121;
}

.fc-0136FE {
    color: #0136fe;
}

.fc-16CC5A {
    color: #16cc5a;
}

.fc-7E0000 {
    color: #7e0000;
}

.fc-809BFF {
    color: #809bff;
}

.fc-7E7E7E {
    color: #7e7e7e;
}

.fc-747474 {
    color: #747474
}

