.box {
    .container-search {
        vertical-align: middle;
        white-space: nowrap;
        position: relative;

        input:focus {
            border: none;
        }

        .search {
            width: 100%;
            height: 31px;
            border: 0.8px solid #000000;
            font-size: 14px;
            float: left;
            color: #63717f;
            padding-left: 35px;
            padding-right: 10px;
            border-radius: 20px;
        }

        .icon {
            position: absolute;
            top: 50%;
            margin-left: 9px;
            margin-top: 4px;
            left: 0;
        }

        .search-icon-right {
            width: 100%;
            height: 38px;
            border: 0.8px solid #707070;
            font-size: 14px;
            float: left;
            color: #63717f;
            padding-left: 15px;
            padding-right: 10px;
            border-radius: 20px;
        }

        .icon-right {
            position: absolute;
            top: 50%;
            margin-left: 11px;
            right: 12px;
            margin-top: 7px;
        }
    }
}
