$btn-padding-x: 1.25rem !default;
$btn-padding-y: 0.65rem !default;
$btn-line-height: 1.25 !default;
$btn-size: 2.2rem !default;
$btn-width: 10rem !default;
$btn-box-shadow:
    inset 0 1px 0 rgba($white, 0.15),
    0 1px 1px rgba($black, 0.075) !default;
$btn-focus-box-shadow: 0 0 0 2px rgba($brand-primary, 0.25) !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;
$btn-primary-color: $white !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: $btn-primary-bg !default;
$btn-danger-color: $white !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: $btn-danger-bg !default;

$btn-link-disabled-color: $gray-lighter !default;
$btn-disabled-color: $gray-lighter !default;

$btn-size-sm: 2rem !default;
$btn-size-lg: 3rem !default;

$btn-padding-x-sm: 1rem !default;
$btn-padding-y-sm: 0.35rem !default;

$btn-padding-x-lg: 1.5rem !default;
$btn-padding-y-lg: 0.75rem !default;

$btn-block-spacing-y: 0.5rem !default;
$btn-toolbar-margin: 0.5rem !default;
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-transition: all 0.2s ease-in-out !default;

.btn {
    font-size: $font-size-base;
    padding: $btn-padding-y $btn-padding-x;
    line-height: $btn-line-height;
    border-color: $border-color;
    color: $body-color;
    //height: $btn-size;
    width: $btn-width;
    border-radius: $border-radius;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: $gray-lighter;
        border-color: $gray-lighter;
        color: $body-color;
    }

    &.ant-btn-clicked {
        &:after {
            border-color: $border-color;
        }
    }

    &.disabled,
    &[disabled] {
        color: rgba($body-color, 0.25);
        background-color: $btn-disabled-color;
        border-color: $border-color;

        &.active,
        &:active,
        &:focus,
        &:hover {
            color: rgba($body-color, 0.25);
            background-color: $btn-disabled-color;
            border-color: $border-color;
        }
    }

    .icon-upload {
        position: absolute;
        right: 0;
        margin-right: 10px;
        top: 0;
        margin-top: 11px;
        width: 16px;
    }

    .icon-upload-left {
        position: absolute;
        left: 0;
        margin-left: 10px;
        top: 0;
        margin-top: 11px;
        width: 18px;
    }

    .img-right {
        position: absolute;
        padding-top: 10px;
    }
}

.btn-sm {
    height: $btn-size-sm;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    font-size: $font-size-base;
    border-radius: $border-radius;
}

.btn-lg {
    height: $btn-size-lg;
    padding: $btn-padding-y-lg $btn-padding-x-lg;
    font-size: $font-size-base;
    border-radius: $border-radius;
}

a {
    &.btn {
        padding-top: $btn-padding-y !important;
        line-height: initial;

        &.btn-sm {
            padding-top: $btn-padding-y-sm;
        }

        &.btn-lg {
            padding-top: $btn-padding-y-lg;
        }
    }

    &.btn-link {
        border: 2px solid $brand-primary;
    }
}

.btn-link {
    color: $brand-primary;
    background-color: transparent;
    border: 0;

    &:hover,
    &:focus {
        background-color: transparent;
        color: $brand-primary;
    }

    &:hover {
        background-color: rgba($brand-primary, 0.1);
    }

    &:active {
        background-color: rgba($brand-primary, 0.2);
    }
}

.btn-icon-only {
    width: $btn-size;
    height: $btn-size;
    font-size: $font-size-base;
    border-radius: $border-radius;
    padding: 0;

    &.btn-lg {
        width: $btn-size-lg;
        height: $btn-size-lg;
        font-size: $font-size-lg;
        border-radius: $border-radius;
    }

    &.btn-sm {
        width: $btn-size-sm;
        height: $btn-size-sm;
        font-size: $font-size-sm;
        border-radius: $border-radius;
    }
}

.btn-circle,
.btn-circle-outline {
    padding: 0;
    min-width: $btn-size;
    border-radius: $border-radius-circle;

    &.btn-lg {
        min-width: $btn-size-lg;
        padding: 0;
        border-radius: $border-radius-circle;
    }

    &.btn-sm {
        min-width: $btn-size-sm;
        padding: 0;
        border-radius: $border-radius-circle;
    }
}

.btn-round {
    border-radius: $btn-size;
    height: fit-content;

    &.btn-lg {
        height: $btn-size-lg;
        border-radius: $btn-size-lg;
        border-top-left-radius: $btn-size-lg;
        border-top-right-radius: $btn-size-lg;
        border-bottom-right-radius: $btn-size-lg;
        border-bottom-left-radius: $btn-size-lg;
        padding: 0 $btn-padding-x-lg;
    }

    &.btn-sm {
        height: $btn-size-sm;
        border-radius: $btn-size-sm;
        border-top-left-radius: $btn-size-sm;
        border-top-right-radius: $btn-size-sm;
        border-bottom-right-radius: $btn-size-sm;
        border-bottom-left-radius: $btn-size-sm;
        padding: 0 $btn-padding-x-sm;
    }
}

.btn-round .btn-icon-only {
    width: auto;
}

.btn-background-ghost {
    border-color: $white;
    color: $white;
    min-width: $btn-width;

    &:hover,
    &:focus {
        border-color: $brand-primary;
        color: $brand-primary;
    }

    &.btn-link {
        color: $white;

        &:hover,
        &:focus {
            color: $brand-primary;
        }

        &:active {
            color: darken($brand-primary, 5%);
        }
    }
}

.btn-primary {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: $white;

    &:hover,
    &:focus {
        color: $white;
        background-color: lighten($brand-primary, 5%);
        border-color: lighten($brand-primary, 5%);
    }

    &:active {
        color: $white;
        background-color: darken($brand-primary, 5%);
        border-color: darken($brand-primary, 5%);
    }

    &.active {
        color: $white;
        background-color: darken($brand-primary, 5%);
        border-color: darken($brand-primary, 5%);

        &:hover,
        &:focus {
            background-color: darken($brand-primary, 5%);
            border-color: darken($brand-primary, 5%);
        }
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
        &:focus {
            box-shadow: none;
        }
    }

    &.btn-background-ghost {
        background-color: transparent;
        border-color: $brand-primary;
        color: $brand-primary;
        min-width: $btn-width;
        width: auto;

        &:hover,
        &:focus,
        &:active {
            border-color: lighten($brand-primary, 5%);
            color: lighten($brand-primary, 5%);
        }
    }

    &.disabled,
    &[disabled] {
        color: rgba($body-color, 0.25);
        background-color: $gray-lightest;
        border-color: $border-color;

        &.active,
        &:active,
        &:hover,
        &:focus {
            color: rgba($body-color, 0.25);
            background-color: $gray-lightest;
            border-color: $border-color;
        }
    }

    &.btn-clicked {
        &:after {
            border-color: $brand-primary;
        }
    }
}

.btn-dangerous {
    color: $brand-danger;
    border-color: $brand-danger;

    &:hover,
    &:focus {
        background-color: transparent;
        color: lighten($brand-danger, 5%);
        border-color: lighten($brand-danger, 5%);
    }

    &:active {
        background-color: transparent;
        color: darken($brand-danger, 5%);
        border-color: darken($brand-danger, 5%);
    }

    &.btn-primary {
        background-color: $brand-danger;
        border-color: $brand-danger;
        color: $white;

        &:hover,
        &:focus {
            color: $white;
            background-color: lighten($brand-danger, 5%);
            border-color: lighten($brand-danger, 5%);
        }

        &:active {
            color: darken($brand-danger, 5%);
            border-color: darken($brand-danger, 5%);
        }
    }

    &.btn-link {
        color: $brand-danger;

        &:hover,
        &:focus {
            background-color: transparent;
            color: $brand-danger;
        }

        &:hover {
            background-color: rgba($brand-danger, 0.1);
        }

        &:active {
            background-color: rgba($brand-danger, 0.2);
        }
    }
}

.btn-default {
    color: $body-color;
    border-color: $border-color;
    background-color: transparent;

    &:hover,
    &:focus {
        background-color: transparent;
        color: lighten($body-color, 5%);
        border-color: lighten($border-color, 5%);
    }

    &:active {
        background-color: transparent;
        color: darken($body-color, 5%);
        border-color: darken($border-color, 5%);
    }

    &.btn-primary {
        background-color: $white;
        border-color: $border-color;
        color: $body-color;

        &:hover,
        &:focus {
            color: $body-color;
            background-color: lighten($white, 5%);
            border-color: lighten($border-color, 5%);
        }

        &:active {
            color: darken($body-color, 5%);
            border-color: darken($border-color, 5%);
        }
    }

    &.btn-link {
        color: $body-color;

        &:hover,
        &:focus {
            background-color: transparent;
            color: $body-color;
        }

        &:hover {
            background-color: rgba($white, 0.1);
        }

        &:active {
            background-color: rgba($white, 0.2);
        }
    }
}

.btn-group {
    .btn-primary {
        + .btn-primary,
        &:last-child:not(:first-child) {
            border-left-color: lighten($brand-primary, 10%);
        }
    }
}

.btn-outline-primary {
    background: $white;
    border-color: $brand-primary;
    border-radius: 999px;
    color: $brand-primary;
    padding: $btn-padding-y $btn-padding-x;
    box-sizing: border-box;
    opacity: 1;
    outline: 0 solid transparent;

    &.btn-link {
        &:hover {
            border: 2px solid rgba($brand-primary, 1);
        }
    }
}

.btn-outline-dark {
    background: $white;
    border-color: $black;
    border-radius: 999px;
    color: $black;
    padding: $btn-padding-y $btn-padding-x;
    box-sizing: border-box;
    opacity: 1;
    outline: 0 solid transparent;

    &.btn-link {
        &:hover {
            border: 2px solid rgba($brand-primary, 1);
        }
    }
}
.btn-outline-blue-ligth {
    background: $white;
    border-color: $purple-ligth;
    border-radius: 999px;
    color: $purple-ligth;
    padding: $btn-padding-y $btn-padding-x;
    box-sizing: border-box;
    opacity: 1;
    outline: 0 solid transparent;

    &:hover {
        // border: 2px solid rgba($brand-primary, 1);
        background-color: $purple-ligth;
        color: $white;
    }
    &:active {
        color: $white !important;
        border-color: $purple-ligth !important;
        color: $purple-ligth !important;
    }
    &:focus {
        color: $white;
        background-color: $purple-ligth;
    }
    &:visited {
        color: $white;
        background-color: $purple-ligth;
    }
    &.btn-link {
        &:hover {
            color: $white;
            background-color: $purple-ligth;
        }
    }
}

.btn-outline-default {
    background-color: #ffffff;
    border: 1px solid rgb(209, 213, 219);
    border-radius: 999px;
    box-sizing: border-box;
    line-height: 1.25rem;
    padding: 0.75rem 1rem;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &:hover {
        background-color: rgb(249, 250, 251);
    }

    &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
    }

    &:focus-visible {
        box-shadow: none;
    }
}

.btn-success {
    background-color: $brand-success;
    border-color: $brand-success;
    color: #fff;

    &:hover,
    &:focus {
        color: $white;
        background-color: lighten($brand-success, 5%);
        border-color: lighten($brand-success, 5%);
    }

    &:active {
        color: $white;
        background-color: darken($brand-success, 5%);
        border-color: darken($brand-success, 5%);
    }

    &.active {
        color: $white;
        background-color: darken($brand-success, 5%);
        border-color: darken($brand-success, 5%);

        &:hover,
        &:focus {
            background-color: darken($brand-success, 5%);
            border-color: darken($brand-success, 5%);
        }
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
        &:focus {
            box-shadow: none;
        }
    }

    &.btn-background-ghost {
        background-color: transparent;
        border-color: $brand-success;
        color: $brand-success;
        min-width: $btn-width;

        &:hover,
        &:focus,
        &:active {
            border-color: lighten($brand-success, 5%);
            color: lighten($brand-success, 5%);
        }
    }

    &.disabled,
    &[disabled] {
        color: rgba($body-color, 0.25);
        background-color: $gray-lightest;
        border-color: $border-color;

        &.active,
        &:active,
        &:hover,
        &:focus {
            color: rgba($body-color, 0.25);
            background-color: $gray-lightest;
            border-color: $border-color;
        }
    }

    &.btn-clicked {
        &:after {
            border-color: $brand-success;
        }
    }
}

.btn-secondary {
    color: #fff;
    background-color: $brand-secondary;
    border-color: $brand-secondary;

    &:hover,
    &:focus {
        color: $white;
        background-color: lighten($brand-secondary, 5%);
        border-color: lighten($brand-secondary, 5%);
    }

    &:active {
        color: $white;
        background-color: darken($brand-secondary, 5%);
        border-color: darken($brand-secondary, 5%);
    }

    &.active {
        color: $white;
        background-color: darken($brand-secondary, 5%);
        border-color: darken($brand-secondary, 5%);

        &:hover,
        &:focus {
            background-color: darken($brand-secondary, 5%);
            border-color: darken($brand-secondary, 5%);
        }
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
        &:focus {
            box-shadow: none;
        }
    }

    &.btn-background-ghost {
        background-color: transparent;
        border-color: $brand-secondary;
        color: $brand-secondary;
        min-width: $btn-width;

        &:hover,
        &:focus,
        &:active {
            border-color: lighten($brand-secondary, 5%);
            color: lighten($brand-secondary, 5%);
        }
    }

    &.disabled,
    &[disabled] {
        color: rgba($body-color, 0.25);
        background-color: $gray-lightest;
        border-color: $border-color;

        &.active,
        &:active,
        &:hover,
        &:focus {
            color: rgba($body-color, 0.25);
            background-color: $gray-lightest;
            border-color: $border-color;
        }
    }

    &.btn-clicked {
        &:after {
            border-color: $brand-secondary;
        }
    }
}
.btn-small {
    width: 7rem !important;
}
