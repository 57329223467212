.table-content {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    padding: 1rem 0;

    .input-content {
        grid-area: 1 / 1 / 2 / 4;
        padding-bottom: 20px;
    }

    .select-content {
        grid-area: 1 / 4 / 2 / 6;
        padding-bottom: 20px;

        .milio-select {
            border-radius: 25px;
            padding: 3px 35px 3px 15px;
            height: 35px;
            width: 100%;

            &:focus {
                outline: none;
                border-color: #DDDD;
                box-shadow: 0 0 0 2px rgba(#DDDDDD, .2);
            }
        }
    }

    .order-content {
        grid-area: 1 / 6 / 2 / 8;
        padding-bottom: 20px;
    }

    .button-content {
        grid-area: 1 / 8 / 2 / 11;
        padding-bottom: 20px;
    }

    .pagination-content {
        grid-area: 2 / 1 / 3 / 11;
    }

    .content-list {
        grid-area: 3 / 1 / 4 / 11;
    }
}

.summary-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    .total-content {
        grid-area: 1 / 1 / 2 / 4;
        display: flex;
        justify-content: center;
    }

    .action-content {
        grid-area: 1 / 4 / 2 / 5;
    }
}

.btn-class {
    border: none;
    background: none;

    span {
        padding-top: 10px;
        padding-left: 8px
    }
}

.btn-style {
    border: 1px solid #000000;
    border-radius: 20px;
    height: 35px;
    width: 100%;
}

.blue {
    background: #F2FCFF !important;
}

@media (max-width: 767.98px) {
    .table-content {
        grid-template-columns: repeat(10, 1fr);

        .input-content { grid-area: 1 / 1 / 2 / 6; }
        .select-content { grid-area: 1 / 6 / 2 / 11; }
        .order-content { grid-area: 2 / 1 / 3 / 6; }
        .button-content { grid-area: 2 / 6 / 3 / 11; }
        .pagination-content { grid-area: 3 / 1 / 4 / 11; }
        .content-list { grid-area: 4 / 1 / 5 / 11; }
    }

    .summary-content {
        grid-template-columns: repeat(4, 1fr);
        color: var(--bs-body-color);

        .total-content {
            grid-area: 1 / 1 / 2 / 3;
            display: block;
            text-align: start;

            p {
                margin-bottom: 0;
            }
        }

        .action-content {
            grid-area: 1 / 3 / 2 / 5;
            padding-top: 15px;
        }
    }
}
