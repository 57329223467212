.ngx-otp-input {
    width: 50px !important;
    font-size: 32px !important;
    height: 50px !important;
    border: .1rem solid #c5c2c2 !important;

    &:focus {
        outline: none !important;
        border: 2px solid $brand-primary !important;
        transition: 0.12s ease-in !important;
    }
}

.login .ngx-otp-input {
    width: 40px !important;
    font-size: 32px !important;
    height: 40px !important;
    border: .1rem solid #c5c2c2 !important;

    &:focus {
        outline: none !important;
        border: 2px solid $brand-primary !important;
        transition: 0.12s ease-in !important;
    }
}

@media (max-width:767px) {
    .ngx-otp-input {
        width: 45px !important;
        font-size: 24px !important;
        height: 45px !important;
    }
}
